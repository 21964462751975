<template>
  <planejamento-acao-form
    :somente-leitura="false"
    :novo="false"
    :edicao="true"
    />
</template>
<script>
// Componente utilizado para criar comportamento de auto redirecionamento
// criando efeito de refresh na rota
import PlanejamentoAcaoForm from './PlanejamentoAcaoForm';

export default {
  components: {
    PlanejamentoAcaoForm,
  },
};
</script>
